@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "roboto_light";
  src: url("./fonts/Roboto-Light.ttf");
}

html {
  background-color: #efefef;
}

ol,
ul {
  padding-left: theme('spacing.10');
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.richtext > p > a {
  color: blue;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
}

iframe {
  width: -webkit-fill-available;
  height: 30rem;
}

.css-11ngvp6 .Content-lens {
  color: #8b173b !important;
}

.table-container {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent wrapping of table content */
}