.img-container {
  background-image: url('../../../assets/images/background.png');
  height: 100vh;
  background-size: cover;
  background-position: center;
}

h3 {
  overflow: hidden;
  text-align: center;

  color: #707070;
}

h3:before,
h3:after {
  background-color: #707070;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h3:before {
  right: 0.5em;
  margin-left: -50%;
}

h3:after {
  left: 0.5em;
  margin-right: -50%;
}
