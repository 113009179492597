.react-datepicker__header {
  background-color: #8b173b !important;
  color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #8b173b !important;
}
.react-datepicker__current-month,
.react-datepicker__day-name {
  color: white !important;
}

.datePickerWrapper {
  width: 80%;
  place-items: center;
}
